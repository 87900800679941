body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

  
.node {
  cursor: pointer;
}

.node circle {
  fill: #fff;
  stroke: #99ccff;
  stroke-width: 3px;
}

.node text {
  font: 12px sans-serif;
}

.link {
  fill: none;
  stroke: #99ccff;
  stroke-width: 2px;
}

.hyper {
  color: red;
  text-decoration: underline;
}

.hyper:hover {
  color: yellow;
  text-decoration: none;
}

.selected {
  font-weight: bold;
}

.not-selected {
  font-weight: normtal;
}

g.node text {
  color: #ffffff;
}

.mapboxgl-marker.mapboxgl-marker-anchor-center:hover {
  z-index: 4 !important;
}

.locDot {
  height: 10px;
  width: 10px;
  justify-content: center;
  align-items: flex-end;
  border: 1.5px solid #fff;
  border-radius: 100%;
}

.locDotSmall {
  height: 8px;
  width: 8px;
  justify-content: center;
  align-items: flex-end;
  border: 1px solid #fff;
  border-radius: 100%;
}

.areaDot {
  height: 16px;
  width: 16px;
  justify-content: center;
  align-items: flex-end;
  border: 2px solid #fff;
  border-radius: 100%;
}

.idTag {
  display: none;
  flex-direction: column;
  padding: 2px 4px;
  font-size: 0.75rem;
  color: white;
  margin-bottom: 18px;
  border-radius: 4px;
  width: fit-content;
  white-space: nowrap;
  margin: 8px 16px;
}

.locDot:hover .idTag, .areaDot:hover .idTag {
  display: flex;
}

.locDotSmall:hover .idTag, .areaDot:hover .idTag {
  display: flex;
}

